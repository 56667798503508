import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getGame } from "../api/Api";
import styles from "../styles/GameDetail.module.scss";

const GameDetail = () => {
  const [gameData, setGameData] = useState();
  const { game } = useParams();

  useEffect(() => {
    getGame(game, setGameData);
  }, [game]);

  return (
    <>
      <div className={styles.game_detail}>
        {gameData ? (
          <>
            <div>
              <span>{gameData.name}</span>
              <br></br>
              <span>Metacritic: {gameData.metacritic}</span>
            </div>
            <>
              <img
                src={gameData.background_image}
                className={styles.bg_image}
              ></img>
              <div className={styles.bg_image_overlay}></div>
            </>
          </>
        ) : null}
      </div>
    </>
  );
};

export default GameDetail;

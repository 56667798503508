import React, { useEffect, useState } from "react";
import styles from "../styles/SearchResults.module.scss";
import { useParams } from "react-router-dom";
import { searchGames } from "../api/Api";

const SearchResults = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { term } = useParams();

  useEffect(() => {
    setSearchTerm(term);
    searchGames(term, setSearchTerm);
    console.log(searchTerm);
  }, [term]);

  return <div className={styles.results}>{term}</div>;
};

export default SearchResults;

import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { getGames } from "../api/Api";
import styles from "../styles/GamesList.module.scss";
import GameCard from "./GameCard";

const GamesList = ({ filter, display }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayStyle, setDisplayStyle] = useState("grid");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const gamesData = await getGames(filter);
        setGames(gamesData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filter]);

  useEffect(() => {
    setDisplayStyle(display);
  }, [display]);

  return (
    <>
      {loading ? (
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#202020", "#202020", "#202020", "#202020", "#202020"]}
        />
      ) : (
        <div className={styles[display]} id="gameList">
          {games.map((game) => (
            <section className={styles.game_card_parent}>
              <div className={styles.game_card_1}>
                <GameCard
                  key={game.id}
                  id={game.id}
                  slug={game.slug}
                  name={game.name}
                  metacritic={game.metacritic}
                  ratings_count={game.ratings_count}
                  released={game.released}
                  genre1={
                    game.genres.length > 0 ? game.genres[0].name : "No genres"
                  }
                  genre2={game.genres.length > 1 ? game.genres[1].name : null}
                  genre3={game.genres.length > 2 ? game.genres[2].name : null}
                  image={game.background_image}
                  rating_icon={
                    game.ratings.length > 0 ? game.ratings[0].title : null
                  }
                  platform1={
                    game.parent_platforms.length > 0
                      ? game.parent_platforms[0].platform.slug
                      : null
                  }
                  platform2={
                    game.parent_platforms.length > 1
                      ? game.parent_platforms[1].platform.slug
                      : null
                  }
                  platform3={
                    game.parent_platforms.length > 2
                      ? game.parent_platforms[2].platform.slug
                      : null
                  }
                  platform4={
                    game.parent_platforms.length > 3
                      ? game.parent_platforms[3].platform.slug
                      : null
                  }
                  platform5={
                    game.parent_platforms.length > 4
                      ? game.parent_platforms[4].platform.slug
                      : null
                  }
                  platform6={
                    game.parent_platforms.length > 5
                      ? game.parent_platforms[5].platform.slug
                      : null
                  }
                  platform7={
                    game.parent_platforms.length > 6
                      ? game.parent_platforms[6].platform.slug
                      : null
                  }
                  platform8={
                    game.parent_platforms.length > 7
                      ? game.parent_platforms[7].platform.slug
                      : null
                  }
                />
              </div>
              <div className={styles.game_card_2}>
                <GameCard
                  key={game.id}
                  id={game.id}
                  slug={game.slug}
                  name={game.name}
                  metacritic={game.metacritic}
                  ratings_count={game.ratings_count}
                  released={game.released}
                  genre1={
                    game.genres.length > 0 ? game.genres[0].name : "No genres"
                  }
                  genre2={game.genres.length > 1 ? game.genres[1].name : null}
                  genre3={game.genres.length > 2 ? game.genres[2].name : null}
                  image={game.background_image}
                  rating_icon={
                    game.ratings.length > 0 ? game.ratings[0].title : null
                  }
                  platform1={
                    game.parent_platforms.length > 0
                      ? game.parent_platforms[0].platform.slug
                      : null
                  }
                  platform2={
                    game.parent_platforms.length > 1
                      ? game.parent_platforms[1].platform.slug
                      : null
                  }
                  platform3={
                    game.parent_platforms.length > 2
                      ? game.parent_platforms[2].platform.slug
                      : null
                  }
                  platform4={
                    game.parent_platforms.length > 3
                      ? game.parent_platforms[3].platform.slug
                      : null
                  }
                  platform5={
                    game.parent_platforms.length > 4
                      ? game.parent_platforms[4].platform.slug
                      : null
                  }
                  platform6={
                    game.parent_platforms.length > 5
                      ? game.parent_platforms[5].platform.slug
                      : null
                  }
                  platform7={
                    game.parent_platforms.length > 6
                      ? game.parent_platforms[6].platform.slug
                      : null
                  }
                  platform8={
                    game.parent_platforms.length > 7
                      ? game.parent_platforms[7].platform.slug
                      : null
                  }
                />
              </div>
            </section>
          ))}
        </div>
      )}
    </>
  );
};

export default GamesList;

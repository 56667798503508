import React, { useEffect, useState } from "react";
import styles from "../styles/Menu.module.scss";
import { ReactComponent as SearchSVG } from "../assets/icons/search.svg";
import { ReactComponent as BellSVG } from "../assets/icons/bell.svg";
import { ReactComponent as DotsSVG } from "../assets/icons/dots.svg";
import { ReactComponent as PlusSVG } from "../assets/icons/plus.svg";
import { ReactComponent as MenuSVG } from "../assets/icons/menu.svg";
import { Link, useNavigate } from "react-router-dom";

const Menu = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const changeTerm = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    navigate(`/results/${search}`);
  };

  return (
    <div className={styles.menu_parent}>
      <div className={styles.menu}>
        <Link to="/" className={styles.home_btn} title="home link">
          RAWG
        </Link>
        <div className={styles.search_bar}>
          <SearchSVG className={styles.search_icon} />
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Search 861,855 games"
              onChange={changeTerm}
            ></input>
          </form>
        </div>
        <div className={styles.icons_parent && styles.menu_desktop}>
          <BellSVG className={styles.icon && styles.bell} />
          <PlusSVG className={styles.icon} />
          <span>API</span>
          <DotsSVG className={styles.icon} />
        </div>
        <div className={styles.menu_mobile_parent}>
          <MenuSVG className={styles.icon && styles.menu_mobile} fill="white" />
        </div>
      </div>
    </div>
  );
};

export default Menu;

import React, { useEffect, useState } from "react";
import GamesList from "../components/GamesList";
import styles from "../styles/Home.module.scss";
import { ReactComponent as Grid } from "../assets/icons/grid.svg";
import { ReactComponent as Column } from "../assets/icons/column.svg";

const Home = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState("grid");

  
  const setChangeFilter = (e) => {
    setFilter(e.target.value);
  };
  
  useEffect(() => {
    const gridElement = document.getElementById("grid");
    const columnElement = document.getElementById("column");

    if (gridElement && columnElement) {
      if (active === "grid") {
        gridElement.classList.add(styles.active);
        columnElement.classList.remove(styles.active);
      } else {
        gridElement.classList.remove(styles.active);
        columnElement.classList.add(styles.active);
      }
    }
  }, [active]);

  return (
    <div className={styles.home}>
      <div className={styles.home_content_parent}>
        <div className={styles.home_content}>
          <div div className={styles.title_big}>
            <h1>New and Trending</h1>
            <h2>Based on player counts and release date</h2>
          </div>
          <div className={styles.title_small}>
            <span>Top picks</span>
            <p>Based on your ratings</p>
          </div>
          <div className={styles.dropdown_parent}>
            <label>Order by</label>
            <select
              className={styles.dropdown}
              onChange={(e) => setChangeFilter(e)}
            >
              <option className={styles.option} value="">
                Popularity
              </option>
              <option className={styles.option} value="name">
                Name
              </option>
              <option className={styles.option} value="added">
                Date added
              </option>
              <option className={styles.option} value="released">
                Release date
              </option>
              <option className={styles.option} value="-rating">
                Average rating
              </option>
            </select>
          </div>
        </div>
        <div className={styles.diplay_options}>
          <span>Display options:</span>
          <Grid
            id="grid"
            className={styles.display_option}
            onClick={() => {
              setActive("grid");
            }}
          />
          <Column
            id="column"
            className={styles.display_option}
            onClick={() => {
              setActive("column");
            }}
          />
        </div>
      </div>
      <GamesList filter={filter} display={active} />
    </div>
  );
};

export default Home;

import React from "react";
import styles from "../styles/GameCard.module.scss";
import ThumbsUp from "../assets/emojis/thumbs-up.png";
import Target from "../assets/emojis/target.png";
import No from "../assets/emojis/no.png";
import Meh from "../assets/emojis/meh.png";
import { ReactComponent as PlusSVG } from "../assets/icons/plus.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaystation,
  faWindows,
  faLinux,
  faXbox,
  faApple,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";
import { faN } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const GameCard = ({ ...props }) => {
  const platformIcons = {
    playstation: faPlaystation,
    pc: faWindows,
    linux: faLinux,
    xbox: faXbox,
    android: faAndroid,
    mac: faApple,
    nintendo: faN,
  };

  return (
    <div className={styles.game_parent}>
      <div className={styles.game}>
        <div className={styles.card_shown}>
          <div className={styles.image_parent}>
            <img src={props.image} className={styles.image_game} alt={props.slug} title={props.slug}/>
          </div>
          <div className={styles.content}>
            <div className={styles.platforms_metacritic}>
              <div className={styles.plarforms}>
                {[
                  props.platform1,
                  props.platform2,
                  props.platform3,
                  props.platform4,
                  props.platform5,
                  props.platform6,
                  props.platform7,
                  props.platform8,
                ]
                  .filter(Boolean)
                  .map((platform, index) => (
                    <span key={index}>
                      {platformIcons[platform.toLowerCase()] && (
                        <FontAwesomeIcon
                          icon={platformIcons[platform.toLowerCase()]}
                          color="white"
                          className={styles.platform_icon}
                        />
                      )}
                    </span>
                  ))}
              </div>
              {props.metacritic ? (
                props.metacritic >= 75 ? (
                  <div className={styles.metacritic + " " + styles.green}>
                    {props.metacritic}
                  </div>
                ) : props.metacritic >= 50 ? (
                  <div className={styles.metacritic + " " + styles.yellow}>
                    {props.metacritic}
                  </div>
                ) : (
                  <div className={styles.metacritic + " " + styles.red}>
                    {props.metacritic}
                  </div>
                )
              ) : null}
            </div>
            <div className={styles.title_parent}>
              <Link
                to={`/games/${props.slug}`}
                className={styles.title}
                key={props.key}
                title={props.name}
              >
                {props.name}
                {props.rating_icon ? (
                  props.rating_icon === "exceptional" ? (
                    <img
                      src={Target}
                      className={styles.target && styles.img_icon} alt="rating" title="rating"
                    />
                  ) : props.rating_icon === "recommended" ? (
                    <img
                      src={ThumbsUp}
                      className={styles.thumb && styles.img_icon} alt="rating" title="rating"
                    />
                  ) : props.rating_icon === "no" ? (
                    <img src={No} className={styles.thumb && styles.img_icon}  alt="rating" title="rating"/>
                  ) : props.rating_icon === "meh" ? (
                    <img
                      src={Meh}
                      className={styles.thumb && styles.img_icon} alt="rating" title="rating"
                    />
                  ) : null
                ) : null}
              </Link>
            </div>
            <div className={styles.ratings}>
              <PlusSVG className={styles.ratings_icon} />
              <a className={styles.ratings_count}>{props.ratings_count}</a>
            </div>
          </div>
        </div>
        <div className={styles.extra_card}>
          <div className={styles.extra}>
            <div className={styles.date}>
              <span className={styles.pre_detail}>Release date:</span>
              <span className={styles.detail}>{props.released}</span>
            </div>
            <div>
              <div className={styles.categ}>
                <span className={styles.pre_detail}>Genres:</span>
                <div className={styles.categ_names}>
                  {props.genre1 ? <span className={styles.detail}>{props.genre1}</span> : null}
                  {props.genre2 ? <span className={styles.detail}>, {props.genre2}</span> : null}
                  {props.genre3 ? <span className={styles.detail}>, {props.genre3}</span> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import Menu from './components/Menu';
import GameDetail from './pages/GameDetail';
import SearchResults from './pages/SearchResults';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Menu />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/results/:term' element={<SearchResults />}/>
          <Route path='/games/:game' element={<GameDetail />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import axios from "axios";

const endpoint = "https://api.rawg.io/api";
const key = "?key=35b511a3cb264c3b8fbee49ade3449ab";

export const getGames = async (filter) => {

  try {
    const response = await axios.get(`${endpoint}/games${key}`, {
      params: {
        ordering: filter,
      }
    });
    const gamesData = response.data.results;
    return gamesData;
  } catch (error) {
    console.log("Error fetching data:", error);
    return [];
  }
};

export const getGame = async(slug, setGameData) =>{
  try{
     const response = await axios.get(`${endpoint}/games/${slug}${key}`);
     const data = response.data;
     setGameData(data);
  } catch (error){
     console.log('Error fetching data: ', error);
     return [];
  }
}

export const searchGames = async(term, setSearchResults) =>{
  try{
    console.log(`${endpoint}/games?search=${term}${key}`);
     const response = await axios.get(`${endpoint}/games?search=${term}${key}`);
     const data = response.data;
     setSearchResults(data);
  } catch (error){
     console.log('Error fetching data: ', error);
     return [];
  }
}